import { CloseButton } from "components/Cart/styles";
import SelectButton, { SelectButtonProps } from "components/SelectButton";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  > p {
    font-size: 1.4rem;
    color: ${Theme.colors.tundora};
  }
`;

type Props = SelectButtonProps & {
  isDay?: boolean;
};

export const StyledSelectButton = styled(SelectButton)<Props>`
  ${({ isDay = false }) => css`
    margin-right: 0;

    > p {
      font-size: 1.4rem;
      font-weight: 400;
    }

    ${isDay &&
    css`
      width: 110px;
      min-width: 110px;
      padding: 1.2rem 1rem;
    `}
  `}
`;

export const SectionSelectButtons = styled.div`
  display: flex;
  gap: 0.8rem;
  overflow-x: auto;
  padding-bottom: 1rem;
`;

export const SectionGridSelectButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const BackStepButton = styled(CloseButton)`
  left: -5.5rem;
`;
