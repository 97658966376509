import { useCallback, useEffect, useState } from "react";
import { format, isToday, isTomorrow } from "date-fns";

import { useAuth } from "contexts/AuthUserContext";
import useCart from "hooks/useCart";

import * as C from "../../styles";
import * as S from "./styles";

type ScheduleDataType = {
  id: number;
  date: string;
  time: Array<{
    start: string;
    end: string;
  }> | null;
};
const SCHEDULE_DATA: ScheduleDataType[] = [
  {
    id: 1,
    date: "2024-11-22T03:00:00.000Z",
    time: [
      { start: "14:00", end: "16:00" },
      { start: "16:00", end: "18:00" },
    ],
  },
  {
    id: 2,
    date: "2024-11-23T03:00:00.000Z",
    time: [
      { start: "09:00", end: "10:00" },
      { start: "10:00", end: "11:00" },
      { start: "14:00", end: "16:00" },
      { start: "16:00", end: "18:00" },
      { start: "18:00", end: "20:00" },
      { start: "20:00", end: "22:00" },
    ],
  },
  {
    id: 3,
    date: "2024-11-24T03:00:00.000Z",
    time: [
      { start: "14:00", end: "16:00" },
      { start: "16:00", end: "18:00" },
      { start: "18:00", end: "20:00" },
      { start: "20:00", end: "22:00" },
      { start: "22:00", end: "00:00" },
    ],
  },
  {
    id: 4,
    date: "2024-11-25T03:00:00.000Z",
    time: [
      { start: "14:00", end: "16:00" },
      { start: "16:00", end: "18:00" },
      { start: "18:00", end: "20:00" },
    ],
  },
  {
    id: 5,
    date: "2024-11-26T03:00:00.000Z",
    time: [{ start: "14:00", end: "16:00" }],
  },
  {
    id: 6,
    date: "2024-11-27T03:00:00.000Z",
    time: [
      { start: "14:00", end: "16:00" },
      { start: "16:00", end: "18:00" },
      { start: "18:00", end: "20:00" },
    ],
  },
  {
    id: 7,
    date: "2024-11-28T03:00:00.000Z",
    time: [
      { start: "14:00", end: "16:00" },
      { start: "16:00", end: "18:00" },
    ],
  },
];

type ScheduleProps = {
  closeSchedule: () => void;
  onSuccess: (address: any) => void;
  isOpen: boolean;
};

const Schedule = ({
  closeSchedule,
  onSuccess,
  isOpen,
}: ScheduleProps) => {
  const [scheduleSelected, setScheduleSelected] =
    useState<ScheduleDataType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();
  const { schedule, setSchedule, setCart } = useCart();

  const handleScheduleDate = useCallback((schedule: ScheduleDataType) => {
    setSchedule({ date: schedule.date, time: null });
    setScheduleSelected(schedule);
  }, []);

  const handleScheduleHour = useCallback(
    (schedule: ScheduleDataType, time: { start: string; end: string }) => {
      setSchedule({ date: schedule.date, time });
      setScheduleSelected(schedule);
      setCart((old) => ({ ...old, schedule: { date: schedule.date, time } }));
    },
    []
  );

  useEffect(() => {
    setSchedule(null);
    setScheduleSelected(null);
  }, []);

  return (
    <C.CartContent>
      <C.MobileMerchantHeader>
        <C.MobileCloseButton
          onClick={closeSchedule}
          style={{ marginLeft: "auto" }}
        >
          <img src="/img/icons-close.svg" />
        </C.MobileCloseButton>
      </C.MobileMerchantHeader>

      <S.BackStepButton onClick={closeSchedule}>
        <img src="/img/icons-left.svg" style={{ marginRight: "3px" }} />
      </S.BackStepButton>

      <C.OverflowContent>
        <C.Section noBorderBottom>
          <S.Wrapper>
            <S.Section>
              <h2>Select date</h2>
              <p>Choose the best date to receive your order.</p>

              <S.SectionSelectButtons>
                {SCHEDULE_DATA.map((item) => {
                  const today = isToday(new Date(item.date));
                  const tomorrow = isTomorrow(new Date(item.date));
                  return (
                    <S.StyledSelectButton
                      key={item.id}
                      selected={schedule?.date === item.date}
                      onClick={() => handleScheduleDate(item)}
                      isDay
                    >
                      <h4>
                        {today
                          ? "Today"
                          : tomorrow
                          ? "Tomorrow"
                          : format(new Date(item.date), "EEEE")}
                      </h4>
                      <p>{format(new Date(item.date), "MMM dd")}</p>
                    </S.StyledSelectButton>
                  );
                })}
              </S.SectionSelectButtons>
            </S.Section>

            <S.Section>
              <h2>Select time</h2>

              {!scheduleSelected ? (
                <p>Select a date to see available times.</p>
              ) : (
                <S.SectionGridSelectButtons>
                  {scheduleSelected?.time?.map((time) => {
                    const [startHour, startMin] = time.start.split(":");
                    const [endHour, endMin] = time.end.split(":");
                    return (
                      <S.StyledSelectButton
                        key={time.start}
                        selected={schedule?.time === time}
                        onClick={() =>
                          handleScheduleHour(scheduleSelected, time)
                        }
                      >
                        {format(
                          new Date().setHours(+startHour, +startMin),
                          "hh:mm aa"
                        )}{" "}
                        -{" "}
                        {format(
                          new Date().setHours(+endHour, +endMin),
                          "hh:mm aa"
                        )}{" "}
                      </S.StyledSelectButton>
                    );
                  })}
                </S.SectionGridSelectButtons>
              )}
            </S.Section>

            <S.Section>{JSON.stringify(schedule, null, 2)}</S.Section>
          </S.Wrapper>
        </C.Section>
      </C.OverflowContent>
    </C.CartContent>
  );
};

export default Schedule;