import * as Case from 'case';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';

import Lottie from 'react-lottie';
import * as animationData from '../../config/magic_stars.json';

import Alert from 'components/Alert';
import Button from 'components/Button';
import Empty from 'components/Empty';
import ItemDetail from 'components/ItemDetail';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import PhoneValidationModal from 'components/PhoneValidationModal';
import PhoneVerificationCodeModal from 'components/PhoneVerificationCodeModal';
import WarningMessage from 'components/WarningMessage';
import { useAuth } from 'contexts/AuthUserContext';
import useCart from 'hooks/useCart';
import useModal from 'hooks/useModal';
import { svgBlinking, toBase64 } from 'utils/svgBlinking';
import AddressListCart from './components/AddressListCart';
import CardList from './components/CardListCart';
import DeliveryAddressForm from './components/DeliveryAddressForm';
import FormAddress from './components/FormAddress';
import FormCardCart from './components/FormCardCart';
import MagicVouchersListCart from './components/MagicVouchersListCart';
import { AddressType, MerchantServicesAvailable } from './interface';
import Schedule from './components/Schedule';

import * as S from './styles';

type CartProps = {
  isOpen: boolean;
  onClose: () => void;
};

const moneyFormatter = (money: number) => '$ ' + (money / 100).toFixed(2);

const Cart = ({ isOpen, onClose }: CartProps) => {
  const {
    cart,
    setCart,
    toggleOpen,
    getTotalItem,
    getTotalVouchers,
    removeFromCart,
    isLoadingCart,
    addresses,
    setAddresses,
    setHasToFetchFee,
    cards,
    setCards,
    profile,
    getProfile,
    handleCompleteOrder,
    bentoSettings,
    magicVoucherAnimation,
    setMagicVoucherAnimation,
    isRangeError,
    rangeErrorMessage,
    hasInfoMessage,
  } = useCart();
  const [isOpenCards, setIsOpenCards] = useState(false);
  const [isOpenNewCard, setIsOpenNewCard] = useState(false);
  const [isOpenAddresses, setIsOpenAddresses] = useState(false);
  const [isOpenNewAddress, setIsOpenNewAddress] = useState(false);
  const [oldAddress, setOldAddress] = useState<AddressType | null>(null);
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const [isPickupAddress, setIsPickupAddress] = useState(false);
  const [isOpenVouchers, setIsOpenVouchers] = useState(false);
  const router = useRouter();
  const { authUser } = useAuth();
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal();
  const { open: openValidationModal, toggle: toggleValidationModal } =
    useModal();
  const { open: openVerificationModal, toggle: toggleVerificationModal } =
    useModal();

  const servicesAvailable = cart.merchant?.servicesAvailable
    ? new Set(cart.merchant?.servicesAvailable)
    : null;
  const isVoucher = servicesAvailable?.has(MerchantServicesAvailable.voucher);
  const isDonation = servicesAvailable?.has(MerchantServicesAvailable.donation);
  const isBentoPackage = cart.merchant?.id === 'aMpH5VC0j6iWA9hRLbdR';
  const hasType = cart.type === 'TAKEOUT' || isDonation || isVoucher;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const isSubmitDisabled = () => {
    return (
      (hasType && cart.paymentMethod) ||
      (cart.type === 'DELIVERY' &&
        cart.paymentMethod &&
        !isRangeError &&
        cart.deliveryFee &&
        Boolean(cart.address) &&
        (cart.subTotal >= (bentoSettings?.bentoDeliveryMinOrder || 1000) ||
          isBentoPackage))
    );
  };

  const handleAddressLink = useCallback(() => {
    if (authUser) {
      if (addresses?.length > 0) {
        setIsOpenAddresses(true);
      } else {
        setIsOpenNewAddress(true);
      }
    } else {
      toggleOpen();
      router.push('/login?return=/cart');
    }
  }, [authUser, toggleOpen, router, addresses]);

  const handlePickupAddressLink = useCallback(() => {
    if (authUser) {
      setIsPickupAddress(true);
      if (addresses?.length > 0) {
        setIsOpenAddresses(true);
      } else {
        setIsOpenNewAddress(true);
      }
    } else {
      toggleOpen();
      router.push('/login?return=/cart');
    }
  }, [authUser, toggleOpen, router, addresses]);

  const handleVouchersLink = useCallback(() => {
    if (authUser) {
      setIsOpenVouchers(true);
    } else {
      toggleOpen();
      router.push('/login?return=/cart');
    }
  }, [authUser, toggleOpen, router]);

  const handlePaymentLink = useCallback(() => {
    if (authUser) {
      if (cards?.length > 0) {
        setIsOpenCards(true);
      } else {
        setIsOpenNewCard(true);
      }
    } else {
      toggleOpen();
      router.push('/login?return=/cart');
    }
  }, [authUser, toggleOpen, router, cards]);

  const openItemDetail = (item: any) => {
    toggleOpen();
    setCurrentItem(item);

    setShowItemDetail(true);
    setModalIsOpen(true);
    const main = document.querySelector('.mainwrapper') as HTMLElement;
    if (main) main.style.overflow = 'hidden';
  };

  const closeItemDetail = () => {
    const main = document.querySelector('.mainwrapper') as HTMLElement;
    if (main) main.style.overflow = 'auto';
    setShowItemDetail(false);
    setModalIsOpen(false);
  };

  const handleSetNewAddress = (address: AddressType | null) => {
    if (isPickupAddress) setCart((old) => ({ ...old, pickupAddress: address }));
    else setCart((old) => ({ ...old, address }));

    setIsPickupAddress(false);

    if (address) {
      setHasToFetchFee(true);
    } else {
      setAddresses([]);
    }
  };

  const renderItemDetail = (item: any, onClose: () => void) => {
    return (
      <Modal widthAuto open={modalIsOpen} onClose={onClose}>
        <ItemDetail itemProp={item} close={onClose} editing find />
      </Modal>
    );
  };

  const renderPhoneValidationModal = () => {
    return (
      <Modal
        title=""
        open={openValidationModal}
        onClose={() => toggleValidationModal(false)}
        widthAuto={!isMobile}
      >
        <PhoneValidationModal
          phone={profile?.phoneNumber}
          onClose={(openVerify, phoneNumber) => {
            phoneNumber && setPhoneNumber(phoneNumber);
            openVerify && toggleVerificationModal(true);
            toggleValidationModal(false);
          }}
        />
      </Modal>
    );
  };

  const renderPhonePhoneVerificationCodeModal = () => {
    return (
      <Modal
        title=""
        open={openVerificationModal}
        onClose={async () => {
          await getProfile();
          handleCompleteOrder();
          toggleVerificationModal(false);
        }}
        widthAuto={!isMobile}
      >
        <PhoneVerificationCodeModal
          phoneNumber={phoneNumber}
          onClose={async () => {
            await getProfile();
            handleCompleteOrder();
            toggleVerificationModal(false);
          }}
        />
      </Modal>
    );
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  useEffect(() => {
    if (isBentoPackage) {
      setCart((old) => ({
        ...old,
        type: 'DELIVERY',
        address: null,
        pickupAddress: null,
      }));
    }
  }, [isBentoPackage]);

  const renderMagicVoucherAnimation = () => {
    return (
      <S.MagicVoucherPanel>
        <S.MagicVoucherContainer>
          <Lottie options={defaultOptions} height={400} width={400} />
          <img src="/img/benny_magic.svg"></img>
          <span>Working my Magic!</span>
        </S.MagicVoucherContainer>
      </S.MagicVoucherPanel>
    );
  };

  return (
    <>
      <S.Wrapper isOpen={isOpen}>
        <S.CartContent>
          {isLoadingCart && (
            <S.LoadingContainer>
              <Loading />
              <S.LoadingOverlay />
            </S.LoadingContainer>
          )}

          <S.MobileMerchantHeader>
            <h2>Your order</h2>

            <S.MobileCloseButton onClick={onClose}>
              <img src="/img/icons-close.svg" />
            </S.MobileCloseButton>
          </S.MobileMerchantHeader>

          {cart?.items?.length === 0 ? (
            <S.EmptyCartContent>
              <Empty
                image="/img/icons-message-empty-state.svg"
                description="You don’t have any items in the cart"
              />
            </S.EmptyCartContent>
          ) : (
            <>
              <S.OverflowContent>
                <S.Section>
                  <S.MerchantHeader>
                    <h4>Your order at</h4>

                    <S.Merchant>
                      {cart.merchant?.logo && (
                        <S.MerchantLogo
                          src={cart.merchant?.logo || ''}
                          width="60"
                          height="60"
                          alt={cart?.merchant?.name}
                          placeholder="blur"
                          blurDataURL={`data:image/svg+xml;base64,${toBase64(
                            svgBlinking(60, 60)
                          )}`}
                        />
                      )}
                      <h2>{cart.merchant?.name}</h2>
                    </S.Merchant>
                  </S.MerchantHeader>

                  {bentoSettings?.warningMessage && (
                    <Alert type="warning">
                      {bentoSettings?.warningMessage}
                    </Alert>
                  )}
                  {cart.merchant?.warningMessage && (
                    <Alert type="warning">
                      {cart.merchant?.warningMessage}
                    </Alert>
                  )}
                </S.Section>

                <S.Section
                  style={{
                    paddingBottom:
                      !cart.type || cart.type === 'TAKEOUT' ? '1.6rem' : 0,
                    paddingTop: isBentoPackage ? 0 : '1.6rem',
                  }}
                >
                  <DeliveryAddressForm
                    cart={cart}
                    handleAddressLink={handleAddressLink}
                    handlePickupAddressLink={handlePickupAddressLink}
                    onDeliverySchedule={setIsOpenSchedule}
                    isBentoPackage={isBentoPackage}
                  />
                </S.Section>

                <S.Section>
                  <S.Items>
                    {cart &&
                      cart?.items?.map((item) => (
                        <S.Item key={item.itemId}>
                          <S.ItemData>
                            <div>
                              <h3>
                                {item.qty}x{' '}
                                {Case.capital(Case.lower(item.name))}
                              </h3>
                              <p>
                                {item.modifiers.map(
                                  (modifier) =>
                                    modifier.options?.length > 0 &&
                                    modifier.options.map((option) => {
                                      return (
                                        option.qty > 0 && (
                                          <Fragment key={option.itemId}>
                                            {Case.capital(
                                              Case.lower(option.name)
                                            )}{' '}
                                            <span>
                                              ({item.qty * option.qty}x{' '}
                                              {moneyFormatter(
                                                item.qty *
                                                  option.qty *
                                                  option.price
                                              )}
                                              )
                                            </span>
                                            <br />
                                          </Fragment>
                                        )
                                      );
                                    })
                                )}
                              </p>
                              {item.comments && <p>{item.comments}</p>}
                            </div>
                            <h3>{moneyFormatter(getTotalItem(item))}</h3>
                          </S.ItemData>

                          <S.ItemActions>
                            <S.ButtonEdit onClick={() => openItemDetail(item)}>
                              Edit
                            </S.ButtonEdit>
                            <S.ButtonRemove
                              onClick={() => removeFromCart(item)}
                            >
                              Remove
                            </S.ButtonRemove>
                          </S.ItemActions>
                        </S.Item>
                      ))}

                    {!isBentoPackage && (
                      <Button
                        onClick={() => {
                          toggleOpen();
                          router.push(
                            `/merchants/${cart.merchant?.slug}/${cart.merchant?.id}`
                          );
                        }}
                        size="medium"
                        minimal
                      >
                        Add more items
                      </Button>
                    )}
                  </S.Items>
                </S.Section>

                <S.Section noPaddingRight>
                  <S.Vouchers>
                    {cart?.magicVouchers?.length > 0 ? (
                      <>
                        {cart.magicVouchers.map((voucher) => (
                          <S.Voucher key={voucher.voucherId}>
                            <S.VoucherImage bg={voucher.image} />
                            <S.VoucherDesc>
                              <h4>{voucher.title}</h4>
                              <p>{moneyFormatter(voucher.balance)} discount</p>
                            </S.VoucherDesc>

                            <S.VerticalLine />

                            <S.VoucherActions>
                              <S.VoucherDeleteButton
                                onClick={() =>
                                  setCart({
                                    ...cart,
                                    magicVouchers: cart.magicVouchers.filter(
                                      (item) =>
                                        item.voucherId !== voucher.voucherId
                                    ),
                                  })
                                }
                              >
                                <img src="/img/icon-delete.svg" />
                              </S.VoucherDeleteButton>
                            </S.VoucherActions>
                          </S.Voucher>
                        ))}

                        <S.ButtonVoucher onClick={handleVouchersLink}>
                          <S.RightIcon
                            src="/img/icons-right.svg"
                            style={{ marginRight: '2rem' }}
                          />
                        </S.ButtonVoucher>
                      </>
                    ) : (
                      <S.EmptyVoucher onClick={handleVouchersLink}>
                        <S.EmptyBox>
                          <S.EmptyVoucherImage bg="/img/magic_voucher_icon.svg" />
                          <S.VoucherDesc>
                            <h4>Magic Voucher</h4>
                            <p>Add a magic voucher</p>
                          </S.VoucherDesc>
                        </S.EmptyBox>

                        <S.RightIcon
                          src="/img/icons-right.svg"
                          style={{ marginRight: '2rem' }}
                        />
                      </S.EmptyVoucher>
                    )}
                  </S.Vouchers>
                </S.Section>

                <S.Section>
                  <S.Totals>
                    <S.TotalItem>
                      <p>Subtotal</p>
                      <p>{moneyFormatter(cart.subTotal)}</p>
                    </S.TotalItem>
                    {!!cart.deliveryFee && cart.type === 'DELIVERY' && (
                      <S.TotalItem
                        style={{
                          padding: '1.6rem 0',
                        }}
                      >
                        <p>Delivery Fee</p>
                        <p>{moneyFormatter(cart.deliveryFee)}</p>
                      </S.TotalItem>
                    )}
                    {!!cart.magicVouchers?.length && (
                      <S.TotalItem
                        style={{
                          padding:
                            !!cart.deliveryFee && cart.type === 'DELIVERY'
                              ? '0 0 1.6rem'
                              : '1.6rem 0',
                        }}
                      >
                        <p>Magic Voucher Discount</p>
                        <p className="voucher">
                          -{' '}
                          {moneyFormatter(getTotalVouchers(cart.magicVouchers))}
                        </p>
                      </S.TotalItem>
                    )}
                    <S.TotalItem
                      style={{
                        paddingTop:
                          !cart.deliveryFee ||
                          cart.type === 'TAKEOUT' ||
                          !cart.type
                            ? '1.6rem'
                            : 0,
                      }}
                    >
                      <h2>Total</h2>
                      <h2>
                        {moneyFormatter(
                          cart.total - getTotalVouchers(cart.magicVouchers)
                        )}
                      </h2>
                    </S.TotalItem>

                    <S.TotalItem
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <p style={{ fontSize: '1.4rem' }}>
                          All prices are in Cayman Islands Dollars
                        </p>
                      </S.TotalItem>
                  </S.Totals>
                </S.Section>

                <S.Section>
                  <S.Payment>
                    <S.PaymentTitle>Payment</S.PaymentTitle>

                    {cart.paymentMethod ? (
                      <S.PaymentCard onClick={handlePaymentLink}>
                        <p>••• {cart.paymentMethod.cardNumberEnd}</p>
                        <S.TextChange>Change</S.TextChange>
                        <S.RightIcon src="/img/icons-right.svg" />
                      </S.PaymentCard>
                    ) : (
                      <S.AddPaymentCard onClick={handlePaymentLink}>
                        <p>Add payment</p>
                        <S.RightIcon src="/img/icons-right.svg" />
                      </S.AddPaymentCard>
                    )}
                  </S.Payment>
                </S.Section>
              </S.OverflowContent>

              <S.ButtonWrapper>
                {cart.type === 'DELIVERY' &&
                  !isBentoPackage &&
                  cart.subTotal <
                    (bentoSettings?.bentoDeliveryMinOrder || 1000) && (
                    <S.MinimumDelivery>
                      <BsFillInfoCircleFill size={14} />
                      Minimum order of{' '}
                      {moneyFormatter(
                        bentoSettings?.bentoDeliveryMinOrder || 1000
                      )}{' '}
                      for delivery
                    </S.MinimumDelivery>
                  )}

                {cart.type === 'DELIVERY' && isRangeError && (
                  <S.MinimumDelivery>
                    <BsFillInfoCircleFill size={14} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: rangeErrorMessage || '',
                      }}
                    ></div>
                  </S.MinimumDelivery>
                )}

                {cart.type === 'DELIVERY' && hasInfoMessage && (
                  <S.MinimumDelivery>
                    <BsFillInfoCircleFill size={14} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: rangeErrorMessage || '',
                      }}
                    ></div>
                  </S.MinimumDelivery>
                )}

                <S.ButtonComplete
                  onClick={() => {
                    profile?.verifiedPhoneNumber
                      ? handleCompleteOrder()
                      : toggleValidationModal(true);
                  }}
                  disabled={!isSubmitDisabled()}
                >
                  Complete Order
                </S.ButtonComplete>
              </S.ButtonWrapper>
            </>
          )}
        </S.CartContent>

        <S.CloseButton onClick={onClose}>
          <img src="/img/icons-close.svg" />
        </S.CloseButton>
      </S.Wrapper>

      {showItemDetail && renderItemDetail(currentItem, closeItemDetail)}

      {authUser && (
        <>
          {/* Add Address Page */}
          <S.Wrapper isOpen={isOpenAddresses}>
            <AddressListCart
              items={addresses}
              handleOpenNewAddress={setIsOpenNewAddress}
              handleCloseAddresses={setIsOpenAddresses}
              onSetAddress={handleSetNewAddress}
              onEditAddress={(address) => setOldAddress(address)}
            />

            <S.CloseButton onClick={() => setIsOpenAddresses(false)}>
              <img src="/img/icons-left.svg" style={{ marginRight: '3px' }} />
            </S.CloseButton>
          </S.Wrapper>

          {/* Add Address Form */}
          <S.Wrapper isOpen={isOpenNewAddress}>
            <FormAddress
              isBentoPackage={isBentoPackage}
              closeNewAddressForm={() => setIsOpenNewAddress(false)}
              oldAddress={oldAddress}
              isOpen={isOpenNewAddress}
              onSuccess={(address) => {
                handleSetNewAddress(address);
                setIsOpenNewAddress(false);
                setIsOpenAddresses(false);
              }}
            />
          </S.Wrapper>

          {/* Schedule */}
          <S.Wrapper isOpen={isOpenSchedule}>
            <Schedule
              isOpen={isOpenSchedule}
              closeSchedule={() => setIsOpenSchedule(false)}
              onSuccess={() => console.log("success")}
            />

            <S.CloseButton onClick={() => setIsOpenSchedule(false)}>
              <img src="/img/icons-left.svg" style={{ marginRight: "3px" }} />
            </S.CloseButton>
          </S.Wrapper>

          {/* Add Magic Vouchers Page */}
          <S.Wrapper isOpen={isOpenVouchers}>
            <MagicVouchersListCart
              closeVouchersMenu={() => setIsOpenVouchers(false)}
              onSetVoucher={(voucher) => {
                setMagicVoucherAnimation(true);
                setTimeout(() => {
                  setMagicVoucherAnimation(false);
                }, 3000);
                setCart((old) => ({
                  ...old,
                  magicVouchers: [...old.magicVouchers, voucher],
                }));
              }}
            />

            <S.CloseButton onClick={() => setIsOpenVouchers(false)}>
              <img src="/img/icons-left.svg" style={{ marginRight: '3px' }} />
            </S.CloseButton>
          </S.Wrapper>

          {/* Add payment Page */}
          <S.Wrapper isOpen={isOpenCards}>
            <CardList
              items={cards}
              handleOpenNewCard={setIsOpenNewCard}
              handleCloseCards={setIsOpenCards}
              onSetPayment={(card) => {
                setCart((old) => ({ ...old, paymentMethod: card }));
                if (!card) {
                  setCards([]);
                }
              }}
            />

            <S.CloseButton onClick={() => setIsOpenCards(false)}>
              <img src="/img/icons-left.svg" style={{ marginRight: '3px' }} />
            </S.CloseButton>
          </S.Wrapper>

          {/* Add payment card */}
          <S.Wrapper isOpen={isOpenNewCard}>
            <FormCardCart
              hasToFetchSettings={isOpenNewCard}
              handleOpenCards={setIsOpenCards}
              handleCloseNewCard={setIsOpenNewCard}
              isOpened={isOpenNewCard}
            />

            <S.CloseButton onClick={() => setIsOpenNewCard(false)}>
              <img src="/img/icons-left.svg" style={{ marginRight: '3px' }} />
            </S.CloseButton>
          </S.Wrapper>
        </>
      )}

      {bentoSettings?.warningMessage && <WarningMessage />}

      {renderPhoneValidationModal()}
      {renderPhonePhoneVerificationCodeModal()}

      <S.Overlay
        aria-hidden={
          !isOpen ||
          !isOpenCards ||
          !isOpenNewCard ||
          !isOpenVouchers ||
          !isOpenAddresses ||
          !isOpenNewAddress ||
          !isOpenSchedule
        }
        onClick={() => {
          onClose();
          setIsOpenCards(false);
          setIsOpenNewCard(false);
          setIsOpenAddresses(false);
          setIsOpenNewAddress(false);
          setIsOpenVouchers(false);
          setIsOpenSchedule(false);
        }}
        isOpen={
          isOpen ||
          isOpenCards ||
          isOpenNewCard ||
          isOpenAddresses ||
          isOpenNewAddress ||
          isOpenVouchers ||
          isOpenSchedule
        }
      />

      {magicVoucherAnimation && renderMagicVoucherAnimation()}
    </>
  );
};

export default Cart;
